import { configureStore, createSlice } from "@reduxjs/toolkit";

const slice=  createSlice({
    name:'Toggle',
    initialState:{
        toggle:false
    },
    reducers:{
        toggle:(state)=>({toggle:!state.toggle})
    }
})
 const store=configureStore
    ({
        reducer: slice.reducer
    })
export const {toggle}=slice.actions;



export default store;
// export default createSlice.reducer;