


import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { lazy, Suspense } from 'react';
import { BiLoaderCircle } from 'react-icons/bi';

const Cover = lazy(()=>import('./components/Cover'));
const About = lazy(()=>import ('./components/About/About.jsx'));
const Service = lazy(()=>import('./components/Service/Services.jsx'));
const Project = lazy(()=>import('./components/Projects/Projects'));
const Contacts = lazy(()=>import('./components/Contact/Contacts'));
// import Cards, { ThreeDCardDemo } from './components/svg/Cards'

function App() {
  
  return (
    <BrowserRouter>
  <Suspense fallback={<div className="w-[100%] flex  justify-center items-center text-3xl h-[100vh] animate-spin"><BiLoaderCircle /></div>}>
  <Routes>
    <Route path="/" element={<Cover/>}/>
    <Route path='/about' element={<About/>}/>
    <Route path='/service' element={<Service/>}/>
    <Route path='/project' element={<Project/>}/>
    <Route path='/contact' element={<Contacts/>}/>

  </Routes>
  </Suspense>
  </BrowserRouter>
  );
}

export default App;
